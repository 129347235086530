<template>
<div class="Part">
  <el-row class="input"><!--功能按钮-->
    <el-button size="mini" type="primary" @click="tianjiadingshi10">添加任务</el-button>
      <el-dialog :title="this.title" :visible.sync="tianjiadingshi" @close="closeDepartmentDialog" @open="classChooseGet" :close-on-click-modal="false" width="800px">
        <div v-loading="loading">
          <el-form :model="form">
            <el-form-item>
              任务名称：<el-input :style="inputStyle" v-model="form.mission1" autocomplete="off" size="mini"></el-input>
              <el-button size="mini" @click="classChoose = true" class="chooseClass">选择房间</el-button>
            </el-form-item>
            <el-form-item>
              有效时间：<el-date-picker
                v-model="form.time1"
                style="width: 380px;"
                unlink-panels
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy 年 MM 月 dd 日"
                value-format="yyyy-MM-dd"
                size="mini"></el-date-picker>
              <el-button size="mini" @click="tianjiaqingjing = true" class="chooseClass">添加模式</el-button>
            </el-form-item>
            <el-form-item>
              选择星期：<el-checkbox-group v-model="form.checkboxGroup" size="mini" class="dispaly-inline">
              <el-checkbox-button v-for="(city,index) in form.cities" :label="calcWeak(city)" :key="index">{{city}}</el-checkbox-button>
            </el-checkbox-group>
            </el-form-item>
            启用考勤：
            <el-radio-group v-model="form.isAttendance">
              <el-radio :label="0">不启用</el-radio>
              <el-radio :label="1">启用</el-radio>
            </el-radio-group>
          </el-form>
          <el-table :data="tableData2" stripe style="width:100%;"><!--数据表-->
            <el-table-column prop="scenePattern" label="情景模式" min-width="100px"></el-table-column>
            <el-table-column prop="executionTime" label="执行时间" min-width="100px">
              <template slot-scope="scope2">
                <el-time-picker
                    v-if="scope2.row.showTime == true"
                    @blur="closeTime(scope2.row)"
                    v-model="scope2.row.executionTime"
                    :clearable="false"
                    format="HH:mm:ss"
                    value-format="HH:mm:ss"
                    size="mini"></el-time-picker>
                <span @click="rowClick(scope2.row)" v-else>{{scope2.row.executionTime}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="Contacts" label="删除" min-width="100px">
              <template slot-scope="scope">
                <el-button
                    size="mini"
                    type="danger"
                    @click="indexDelete(scope.$index)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div slot="footer" align="right">
            <el-button @click="tianjiadingshi = false">取 消</el-button>
            <el-button type="primary" v-if="showState" @click="xiugaidingshiPut">保 存</el-button>
            <el-button type="primary" v-else @click="tianjiadingshiSave">保 存</el-button>
          </div>
        </div>
      </el-dialog>
      <el-dialog title="选择房间" :visible.sync="classChoose" @close="cleanGrant" :show-close="false" :close-on-click-modal="false" width="900px">
        <el-row class="transferStyle"><!--穿梭框-->
          <el-col>
            <el-transfer
                    :data="classroom.equipmentData"
                    v-model="classroom.equipmentSelection"
                    :titles="classroom.equipmentTitle"
                    :props="{key: 'hsId',label: 'hsAddCommunity'}"
                    filterable
                    filter-placeholder="搜索房间"
            ></el-transfer>
          </el-col>
        </el-row>
        <div slot="footer">
          <el-button @click="classChooseCancel">取 消</el-button>
          <el-button type="primary" @click="classChooseSave">保 存</el-button>
        </div>
      </el-dialog>
      <el-dialog title="情景模式" :visible.sync="tianjiaqingjing" width="30%">

        <el-form :model="form">
          <el-form-item>
            情景模式：<el-select v-model="form.state2" placeholder="请选择" size="mini" style="width: 220px">
              <el-option
                      v-for="(item,i) in tableDataQuery2"
                      :key="i"
                      :label="item.scenePattern"
                      @click.native="onChange(item.scenePatternId)"
                      :value="item.scenePattern">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            时间选择：<el-time-picker
                  v-model="form.timechange"
                  format="HH:mm:ss"
                  value-format="HH:mm:ss"
                  size="mini"></el-time-picker>
          </el-form-item>
        </el-form>

        <div slot="footer">
          <el-button @click="tianjiaqingjing = false">取 消</el-button>
          <el-button type="primary" @click="tianjiaqingjing10">保 存</el-button>
        </div>
      </el-dialog>

    <el-button size="mini" type="primary" @click="xiugaidingshiOn">修改任务</el-button>&nbsp;
    <el-row style="padding: 5px 5px 5px 0;">
      任务状态：<el-select v-model="taskStatus" @change="getData" clearable size="mini" placeholder="请选择">
      <el-option label="正常" value="正常"></el-option>
      <el-option label="注销" value="注销"></el-option>
    </el-select>
    </el-row>
      
  </el-row>
<!--  @cell-click="cellClick"-->
  <div style="text-align:center;"><!--数据表-->
    <el-table
    :data="dataList"
    highlight-current-row
    @current-change="currentChangeData"
    :height="tableHeight"
    @row-dblclick="dbClickData2"
    cell-class-name="cell-selection"
    stripe style="width:100%;"
    :key="key">
      <el-table-column prop="jttTaskName" label="任务名称" min-width="100px"></el-table-column>
      <el-table-column prop="jttStartTime" label="有效开始时间" min-width="100px"></el-table-column>
      <el-table-column prop="jttEndTime" label="有效结束时间" min-width="100px"></el-table-column>
      <el-table-column label="任务状态" min-width="100px">
        <template slot-scope="scope">
          <el-select v-model="scope.row.jttTaskStatus" v-if="scope.row.state" @change="startSaskOld(scope.row)" size="mini">
            <el-option label="正常" value="正常"></el-option>
            <el-option label="注销" value="注销"></el-option>
          </el-select>
          <span v-else @click="cellClick(scope)">{{scope.row.jttTaskStatus}}&nbsp;<i class="el-icon-arrow-down"></i></span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination 
      background layout="total, sizes, prev, pager, next, jumper"
      :page-count="pages"
      :page-size="size"
      :total="total"
      :current-page="current" 
      @current-change="handleCurrentChange" 
      @size-change="handleSizeChange" 
      :page-sizes="[5,10,20,40]"
      ></el-pagination>
  </div>
</div>
</template>
<script>
  let timer = null
    export default {
  name:'timingControl',
  data(){
    return{
      taskStatus: '正常',
      loading: false,
      key: 0,
      tableHeight: window.innerHeight - 185,
      dialogVisible: false,//任务状态弹出框
      promptState: '',//提示信息
      tianjiadingshi: false,
      tianjiaqingjing: false,
      classChoose: false,
      tianjiaqingjing2: false,
      jiaoshixuanze2: false,
      //添加和修改的保存状态
      showState: false,
      //当列表改变时
      rowData: '',
      rangeDate: [],
      title: '',

      //启动注销任务状态
      // switchSask: '',

      form: {
        mission1: '',
        date1: '',
        checkboxGroup: [],
        cities: ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期天'],
        time1: [],
        change1: '',
        isAttendance: 0,

        //房屋类型初始化
        state2: '',
        scenePatternId: '',
        timechange: '',

      },
      //选择房间相关
      classroom: {
        hsAddCity: '房间',
        add: '',
        state1: '正常',
        equipmentTitle: ['全部房间','已选房间'],
        equipmentSelection: [],
        equipmentData: []
      },
      cacheData: [],
      inputStyle:{width:'380px'},

      dataList: [],
      classData: [],

      tableDataQuery2: [],//情景模式查询
      tableData2: [],   //添加任务情景列表

      total: 0,         //总条数
      size: 10,        //每页的条数
      pages: 0,      //每页数据
      current: 0,    //初始页

      multipleSelection:[],
      accept: [],
    }
  },
  created() {
    this.getData();
    this.tianjiaqingjingDownList();
  },
  methods: {
    getData() {
      this.$axios({
        method: 'get',
        url: '/common2/timingTasks',
        params: {
          jttTaskStatus: this.taskStatus,
          cursor: this.current,
          limit:this.size
        }
      }).then(res=>{
        this.dataList=res.data.result.records;
        var dataPage=res.data.result;
        this.total=dataPage.total;
        this.size=dataPage.size;
        this.pages=dataPage.pages;
        this.current=dataPage.current;
        for (let i of this.dataList) {
          i.state = false
        }
      }).catch(err => {
        this.isError(err,this)
      })
    },
    handleSizeChange(size) {
      this.size = size;
      this.getData()
    },
    handleCurrentChange(current) {
      this.current = current;
      this.getData()
    },
    closeDepartmentDialog(){//关闭时清空弹框
      this.form = {
        mission1: '',
        change1: '',
        date1: '',
        checkboxGroup: [],
        cities: ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期天'],
        time1: [],
        isAttendance: 0,

        state2: '',
        timechange: '',

      },
      this.tableData2 = []
      this.classroom.equipmentSelection= []
      this.classroom.equipmentData= []
    },
    currentChangeData(row){//当表改变时
      console.log(row)
      this.rowData = row;
      for (let j of this.dataList) {
        j.state = false
      }
    },
    xiugaidingshiOn(){//修改定时按钮
      if(this.rowData == ''){
        this.$message.warning('请选择一条信息')
      }else{
        this.dbClickData2()
      }
    },
    dbClickData2(){//双击弹出修改框
      // clearTimeout(timer)
      this.tianjiadingshi = true
      this.title = '修改任务'
      this.showState = true
      // console.log(this.rowData)
      //赋值
      this.form.mission1 = this.rowData.jttTaskName
      this.form.checkboxGroup = JSON.parse(this.rowData.jttWeeklyChoices).map(String)
      this.tableData2 = eval(this.rowData.jttTaskContent)
      if (this.rowData.jttStartTime != undefined || this.rowData.jttEndTime != undefined) {
        this.form.time1 = []
        this.form.time1[0] = this.rowData.jttStartTime
        this.form.time1[1] = this.rowData.jttEndTime
      }
      this.form.isAttendance = this.rowData.jttAttendanceEnable
      // this.rowData.jttClassRoom classroom.equipmentSelection
      if(typeof this.rowData.jttClassRoom == 'string'){
        this.rowData.jttClassRoom = JSON.parse(this.rowData.jttClassRoom)
      }
      this.cacheData = []
      for(let item of this.rowData.jttClassRoom){
        this.classroom.equipmentSelection.push(item.hsId)
        this.cacheData.push(item.hsId)
      }
    },
    //房间选择后保存
    classChooseSave(){
      this.cacheData = this.classroom.equipmentSelection
      this.classChoose = false
    },
    classChooseCancel(){//选择房间取消
      this.classroom.equipmentSelection = this.cacheData
      this.classChoose = false
    },
    startSaskOld(index) {
      this.rowData = index
      if(this.rowData.jttTaskStatus == "正常"){
        this.promptState = "启用"
      }else{
        this.promptState = "撤销"
      }
      // this.$confirm(`是否要${this.promptState}任务, 是否继续?`, '提示', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning'
      // }).then(() => {
        this.startSask()
      //   this.$message({
      //     type: 'success',
      //     message: `已启用${this.rowData.jttTaskStatus}`
      //   });
      // }).catch(() => {
      //   this.cloesSask()
      //   this.$message({
      //     type: 'info',
      //     message: '已取消'
      //   });
      // });
      this.key--
    },
    cellClick(scope,column,cell,event){//点击班次单元格是打开下拉框
      if(scope.column.label === '任务状态'){
        for (let i of this.dataList) {
          i.state = false
        }
        scope.row.state = true
        this.key++
      }
      // timer = setTimeout(() => {
      //   if (column.label === '任务状态') {
      //     for (let i of this.dataList) {
      //       i.state = false
      //     }
      //     row.state = true
      //     this.key++
      //   }else{
      //     for (let j of this.dataList) {
      //       j.state = false
      //     }
      //     row.state = false
      //     this.key++
      //   }
      // }, 400);
    },
    cellClassName({row,column,rowIndex,columnIndex}){//给班次列单元格增加class名
      if (column.label === '任务状态') {
        return 'cell-selection'
      }
    },
    cloesSask(){
      this.dialogVisible = false
      this.getData()
    },
    startSask(){//启动任务按钮
      this.$axios({
        method: 'put',
        url: `/common2/timingTasks/${this.rowData.jttId}`,
        data: {
          jttTaskStatus: this.rowData.jttTaskStatus
        }
      }).then(res => {
        this.$message.success('修改成功')
        this.dialogVisible = false
        this.getData()
      }).catch(err => {
        this.isError(err,this)
      })
    },
    postTiming(){//添加部门请求
      let equipmentArr = []
      for(let hsId of this.classroom.equipmentSelection){
        for(let item of this.classroom.equipmentData){
          if(hsId == item.hsId){
            let obj = {hsId: item.hsId,hsAddCommunity: item.hsAddCommunity}
            equipmentArr.push(obj)
          }
        }
      }
      this.loading = true
      this.$axios({
        method: 'post',
        url: '/common2/timingTasks',
        data: {
          jttClassRoom: JSON.stringify(equipmentArr),
          jttTaskName: this.form.mission1,
          jttStartTime: this.form.time1[0],
          jttEndTime: this.form.time1[1],
          jttWeeklyChoices: JSON.stringify(this.form.checkboxGroup.map(String)),
          jttTaskContent: JSON.stringify(this.tableData2),
          jttAttendanceEnable: this.form.isAttendance,
        }
      }).then(res => {
        this.tianjiadingshi = false
        this.getData();
        this.loading = false
        this.$message.success('添加成功')
      }).catch(err => {
        this.loading = false
        this.isError(err,this)
      })
    },
    tianjiadingshiSave(){
      if(this.form.mission1 == ''){
        this.$message.warning('任务名称不能为空')
      }else{
        this.postTiming()
      }
    },
    tianjiadingshi10(){
      this.tianjiadingshi = true
      this.showState = false
      this.title = '添加任务'
    },
    tianjiaqingjingDownList(){//情景模式下拉列表
      this.$axios({
        method: 'get',
        url: "/common2/scenarioPatternDescriptions"
      }).then(res=>{
        for(let i=0;i<res.data.result.length;i++){
          this.tableDataQuery2[i] = {}
          this.tableDataQuery2[i].scenePattern = res.data.result[i].spdDescribe
          this.tableDataQuery2[i].scenePatternId = res.data.result[i].spdId
        }
      }).catch(err => {
        this.isError(err,this)
      })
    },
    onChange(val){//v-model传参数
      this.form.scenePatternId = val
    },
    tianjiaqingjing10(){//添加情景
      if(this.form.state2 == '' || this.form.timechange == '' || this.form.timechange == undefined){
        this.$message.warning('信息不能为空')
      }else{
        this.tianjiaqingjing = false
        this.tableData2.push({'scenePattern': this.form.state2, 'executionTime': this.form.timechange, 'scenePatternId': this.form.scenePatternId, 'showTime': false})
      }
    },
    indexDelete(index){
      this.tableData2.splice(index,1)
    },
    //修改定时PUT
    xiugaidingshiPut(){
      if(this.form.mission1 == ''){
        this.$message.warning('任务名称不能为空')
      }else{
        let equipmentArr = []
        for(let hsId of this.classroom.equipmentSelection){
          for(let item of this.classroom.equipmentData){
            if(hsId == item.hsId){
              let obj = {hsId: item.hsId,hsAddCommunity: item.hsAddCommunity}
              equipmentArr.push(obj)
            }
          }
        }
        this.loading = true
        this.$axios({
          method: 'put',
          url: `/common2/timingTasks/${this.rowData.jttId}`,
          data: {
            jttClassRoom: JSON.stringify(equipmentArr),
            jttTaskName: this.form.mission1,
            jttStartTime: this.form.time1[0],
            jttEndTime: this.form.time1[1],
            jttWeeklyChoices: JSON.stringify(this.form.checkboxGroup.map(String)),
            jttTaskContent: JSON.stringify(this.tableData2),
            jttAttendanceEnable: this.form.isAttendance,
          }
        }).then(res => {
          this.tianjiadingshi = false
          this.getData()
          this.loading = false
          this.$message.success('修改成功')
        }).catch(err => {
          this.loading = false
          this.isError(err,this)
        })
      }
    },
    classChooseGet(){
      this.loading = true
      this.classroom.equipmentData = []
      this.$axios({
        method: 'get',
        url: '/common2/house4stores/list',
        params: {
          hsState: this.classroom.state1,
          // houseAddress: this.classroom.hsAddCity,
          // houseAddress: this.classroom.add
          houseAddress: ''
        }
      }).then(res => {
        this.loading = false
        let result = res.data.result
        for(let i of result){
          let a = i.hsAddCommunity == null || i.hsAddCommunity == undefined ? '' : `${i.hsAddCommunity}\u3000`
          let b = i.hsAddBuilding == null || i.hsAddBuilding == undefined ? '' : `${i.hsAddBuilding}\u3000`
          let c = i.hsAddDoorplateno == null || i.hsAddDoorplateno == undefined ? '' : `${i.hsAddDoorplateno}`
          i.hsAddCommunity = a + b + c
        }
        for(let i = 0 ; i < result.length ; i++){
          let obj = {hsId: result[i].hsId , hsAddCommunity: result[i].hsAddCommunity}
          this.classroom.equipmentData.push(obj)
        }
      }).catch(err => {
        this.isError(err,this)
        this.loading = false
      })
    },
    //关闭选择房间清空
    cleanGrant(){
      // this.classroom.hsAddCity= '房间',
      // this.classroom.add= '',
      // this.classroom.state1= '正常',
      // this.classroom.equipmentTitle= ['全部房间','已选房间']
    },
    rowClick(timeRow){
      this.$set(timeRow,'showTime',true)
      // timeRow.showTime = true
    },
    closeTime(timeRow){
      this.$set(timeRow,'showTime',false)
      // timeRow.showTime = false
    },
    calcWeak(val){
      switch (val) {
        case "星期天":
          return "1"
        case "星期一":
          return "2"
        case "星期二":
          return "3"
        case "星期三":
          return "4"
        case "星期四":
          return "5"
        case "星期五":
          return "6"
        case "星期六":
          return "7"
      }
    }
  }
}

</script>
<style type="text/css">
  .dispaly-inline{
    display: inline;
  }
</style>
<style lang="scss" scoped>
  .cell-selection .cell span .el-icon-arrow-down{
    display: none;
  }
  .cell-selection .cell span:hover{
    background: #CCC;
    padding: 10px;
    .el-icon-arrow-down{
      display: inline;
    }
  }
  .chooseClass{
    font-size: 12px!important;
    background-color: transparent!important;
    border-color: #A6A6A6!important;
    margin-left: 10px;
  }
</style>